<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      @queryByIdHandler="queryById"
      @deleteByIdHandler="deleteById"
      @batchDeleteHandler="batchDelete"
      @addHandler="saveTripEventLog"
      :loading="loading"
      :showAdd="false"
      :showDelete="false"
    >
      <template v-slot:search>
        <el-form-item label="车牌号:">
          <el-input
            v-model="search.carNum"
            placeholder="请输入车牌号"
          ></el-input>
        </el-form-item>

        <el-form-item label="司机电话:">
          <el-input
            v-model="search.driverMobile"
            placeholder="请输入司机电话"
          ></el-input>
        </el-form-item>

        <el-form-item label="乘客联系电话:">
          <el-input
            v-model="search.mobile"
            placeholder="请输入乘客联系电话"
          ></el-input>
        </el-form-item>

        <el-form-item label="下车时间:">
          <el-date-picker
            v-model="TimeArr"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :default-time="defaultTimeRange"
            format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="userName" label="乘客姓名"></el-table-column>
        <el-table-column prop="mobile" label="乘客联系电话" ></el-table-column>
        <el-table-column prop="carNum" label="车牌号" ></el-table-column>
        <el-table-column prop="driverName" label="司机姓名"></el-table-column>
        <el-table-column prop="driverMobile" label="司机电话" ></el-table-column>
        <el-table-column label="下车时间" >
          <template scope="s">
            {{ dateFormatType(s.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="destination" label="下车地点"></el-table-column>
        <el-table-column label="支付状态">
          <template scope="s">
            {{ s.row.status|statusFilter}}
          </template>
        </el-table-column>
        <el-table-column prop="estimatedAmountStr" label="金额(元)"></el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
  <script>
import TableCompnent from "@/components/table/TablesComponents";
import TripEventLogApi from "@/api/TripEventLogApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
export default {
  name: "TripEventLogList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  filters:{
    statusFilter(val){
      if(val==1){
        return '已上车';
      }else if(val==5){
        return '待支付';
      }else if(val==9){
        return '已支付';
      }
      return '未知';
    }
  },
  data() {
    let that = this;
    return {
      tableData: {},
      search: {
        carNum: "",
        driverMobile: "",
        mobile: "",
        startTime: "",
        endTime: "",
      },
      TimeArr: [],
      page: {},
      loading: true,
      defaultTimeRange: ["00:00:00", "00:00:00"],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          that.pickerMinDate = minDate.getTime();
          if (maxDate) {
            that.pickerMinDate = null;
          }
        },
        // disabledDate: (time) => {
        //   if (that.pickerMinDate) {
        //     const day30 = (30 - 1) * 24 * 3600 * 1000;
        //     let maxTime = that.pickerMinDate + day30;
        //     if (maxTime > new Date()) {
        //       maxTime = new Date();
        //       return (
        //         time.getTime() > maxTime ||
        //         time.getTime() < that.pickerMinDate - day30
        //       );
        //     } else {
        //       return (
        //         maxTime < time.getTime() ||
        //         time.getTime() < that.pickerMinDate - day30
        //       );
        //     }
        //   }

        //   return time.getTime() > Date.now();
        // },
      },
    };
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.TimeArr = [];
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      if (this.TimeArr == null) {
        this.search.startTime = "";
        this.search.endTime = "";
      } else if (this.TimeArr && this.TimeArr.length != 0) {
        this.search.startTime = this.TimeArr[0];
        this.search.endTime = this.TimeArr[1];
      }
      TripEventLogApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveTripEventLog() {
      navTo(this, "/saveTripEventLog");
    },
    queryById(id) {
      TripEventLogApi.queryTripEventLogById(id).then((resp) => {
        if (resp.code === '200') {
          queryParam(this, "saveTripEventLog", resp.data);
        }
      });
    },
    deleteById(id) {
      TripEventLogApi.deleteTripEventLog(id).then((resp) => {
        if (resp.code === '200') {
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    batchDelete(params) {
      TripEventLogApi.batchDeleteTripEventLog(params).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    dateFormatType(val) {
      if (!val) {
        return "";
      }
      var date = new Date(val);
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // js从0开始取
      var date1 = date.getDate();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var second = date.getSeconds();
      if ((month + "").length === 1) {
        month = "0" + month;
      }
      if ((date1 + "").length === 1) {
        date1 = "0" + date1;
      }
      if ((hour + "").length === 1) {
        hour = "0" + hour;
      }
      if ((minutes + "").length === 1) {
        minutes = "0" + minutes;
      }
      if ((second + "").length === 1) {
        second = "0" + second;
      }

      return (
        year +
        "-" +
        month +
        "-" +
        date1 +
        "  " +
        hour +
        ":" +
        minutes +
        ":" +
        second
      );
    },
  },
};
</script>
  <!--页面代码从这里结束拷贝-->